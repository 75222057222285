/*
 * Shortcode: Flip-Box.less
 * -----------------------------------------------
*/
.card {
	float: left;
	min-height: 300px;
	padding-bottom: 25%;
	position: relative;
	text-align: center;
	width: 100%;

	&:nth-child(1) {
		margin-left: -3px;
  		margin-right: 1px;
	}

	&:nth-child(1),.card:nth-child(3) {
  		margin-right: 1px;
	}

	.card__front,.card__back {
		position: absolute;
	  	top: 0;
	  	left: 0;
	  	width: 100%;
	  	height: 100%;
 		-webkit-backface-visibility: hidden;
         backface-visibility: hidden;
         .transition(transform 0.3s);
	}

	.card__back {
	  	-webkit-transform: rotateY(-180deg);
	  	transform: rotateY(-180deg);
	}

	.card__text {
	  display: inline-block;
	  position: absolute;
	  top: 0;
	  right: 0;
	  bottom: 0;
	  left: 0;
	  margin: auto;
	  height: 300px;
	  line-height: 20px;
	}

	&.effect__hover {
		&:hover {
			.card__front {
	  			-webkit-transform: rotateY(-180deg);
	          			transform: rotateY(-180deg);
			}
			.card__back  {
				-webkit-transform: rotateY(0);
          				transform: rotateY(0);
			}
		}
	}

	&.effect__click {
		&.flipped {
			.card__front {
	  			-webkit-transform: rotateY(-180deg);
	          			transform: rotateY(-180deg);
			}
			.card__back  {
				-webkit-transform: rotateY(0);
          				transform: rotateY(0);
			}
		}
	}

	&.effect__random {
		&.flipped {
			.card__front {
	  			-webkit-transform: rotateY(-180deg);
	          			transform: rotateY(-180deg);
			}
			.card__back  {
				-webkit-transform: rotateY(0);
          				transform: rotateY(0);
			}
		}
	}

}
