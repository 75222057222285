/*
 * About.less
 * -----------------------------------------------
*/

/* -------- Bg Img Box ---------- */
.bg-img-box {
	position: relative;

	.caption {
		padding-left: 30px;
		padding-right: 30px;
		padding-top: 20px;
		position: absolute;
		top: 0;

		.title {
			font-size: 30px;
			line-height: 39px;
		}

		p {
			max-width: 280px;
		}
	}
}