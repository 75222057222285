/*
 * Shortcode: Testimonials.less
 * -----------------------------------------------
*/
.testimonial .content {
  position: relative;
}
.testimonial .content::after {
  color: #202c45;
  content: "\f10e";
  font-family: fontawesome;
  font-size: 48px;
  opacity: 0.2;
  position: absolute;
  right: 20px;
  top: -15px;
}