/*
 * 1.1 -> Courses.less
 * -----------------------------------------------
*/

.courses:hover .courses-thumb span {
  right: 0;
}
.courses-thumb {
	position: relative;
	overflow: hidden;
}
.courses-thumb span {
	color: @white-base;
	font-size: 14px;
	font-weight: 600;
	padding: 2px 8px;
	position: absolute;
	right: -48px;
	text-shadow: 1px 2px 1px @black-333;
	top: 0;
	transition: all 300ms ease-in-out 0s;
}