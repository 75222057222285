@charset "utf-8";
/*------------------------------------------------------------------
[Master Stylesheet]

Project:        horseman
Version:        2.0
Primary use:    horseman | Car Repair & Car Wash HTML Template
Author:         ThemeMascot
URL:            http://themeforest.net/user/ThemeMascot
-------------------------------------------------------------------*/
 
// Table of contents
@import "less-horseman/table-of-content.less";


// Import bootstrap variables and mixins for reference
@import (reference) "less-bootstrap/variables.less";
@import (reference) "less-bootstrap/mixins.less";

//fonts
@import "../css/font-awesome.min.css";
@import "../css/font-awesome-animation.min.css";
@import "../css/pe-icon-7-stroke.css";
/* @import "../css/elegant-icons.css"; */
/* @import "../css/stroke-gap-icons.css"; */
@import "../css/flaticon-set-horseman.css";
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,500,600,700,800|Raleway:400,200,300,500,600,700,800,900|Titillium+Web:400,200,300,600,700,900|Lora:400,700,400italic,700italic);
 
// Initialize Variables
@import "less-horseman/variables.less";
@import "less-horseman/mixins.less";
// Typography
@import "less-horseman/typography.less";

// Common Styles
@import "less-horseman/common.less";
@import "less-horseman/extra.less";
@import "less-horseman/overlay.less";

// Header
@import "less-horseman/header.less";

// Nav
@import "less-horseman/nav.less";

// Content Blocks
@import "less-horseman/topbar.less";
@import "less-horseman/courses.less";
@import "less-horseman/inner-header-title.less";
@import "less-horseman/vertical-nav.less";
@import "less-horseman/menu-full-page.less";
@import "less-horseman/boxed-layout.less";
@import "less-horseman/form.less";
@import "less-horseman/side-push-panel.less";
@import "less-horseman/box-hover-effect.less";
@import "less-horseman/work-gallery.less";
@import "less-horseman/gallery-isotope.less";
@import "less-horseman/sliders.less";
@import "less-horseman/home.less";
@import "less-horseman/about.less";
@import "less-horseman/contact.less";
@import "less-horseman/services.less";
@import "less-horseman/shop.less";
@import "less-horseman/blog.less";

// Shortcodes
@import "less-horseman/shortcodes.less";


// Widgets
@import "less-horseman/widgets.less";


// Footer
@import "less-horseman/footer.less";