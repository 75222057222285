/*
 * Topbar.less
 * -----------------------------------------------
*/
#top-search-bar {
  background: #eee;
}
#top-search-bar form {
  position: relative;
}
#top-search-bar .form-control {
  background: #eee;
  border: none;
  box-shadow: none;
  font-size: 18px;
  height: 72px;
  margin: 0;
  width: 100%;
}
#top-search-bar .search-close {
  background: none repeat scroll 0 0 transparent;
  border: medium none;
  color: #999;
  cursor: pointer;
  font-size: 14px;
  height: 40px;
  line-height: 38px;
  margin-top: -20px;
  outline: medium none;
  overflow: hidden;
  position: absolute;
  right: 1px;
  top: 50%;
  width: 42px;
}
#top-search-bar button i {
  color: #777;
}