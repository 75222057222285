/*
 * Shortcode: Pricing.less
 * -----------------------------------------------
*/
.pricing-table {
	background: #f8f8f8 none repeat scroll 0 0;
	margin-bottom: 30px;
	padding: 10px 0 30px;
	position: relative;
	text-align: center;
}
.pricing-table .table-price {
	border-radius: 0 0 25% 25%;
	color: #fff;
	font-size: 30px;
}
.pricing-table .table-price span {
	font-size: 14px;
}
.pricing-table .table-list {
	list-style: outside none none;
	padding: 10px 0 10px 20px;
}
.pricing-table .table-list li {
	border-bottom: 0 none;
	font-size: 13px;
	padding: 15px 0;
}
.pricing-table .table-list li:nth-child(2n) {
	background: #fff none repeat scroll 0 0;
}



